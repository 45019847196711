import { Box } from '@mui/material'

import Text from '../Text'

const MessageDevelopmentComponent = (): JSX.Element => {

  return <Box
    sx={{
      width: '100%',
      height: 'auto',
      padding: '1rem',
      backgroundColor: 'red',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <Text 
      text='Este é um ambiente para testes, nenhuma informação cadastrada aqui, será valida!'
      type='body1'
      align='center'
      sx={{
        color: '#fff',
        fontSize: { xs: '.8rem', sm: '1.2rem' }
      }}
    />
  </Box>
}

export default MessageDevelopmentComponent